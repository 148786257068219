@import '@aurora/shared-client/styles/_variables.pcss';

.lia-control.lia-control {
  margin: 15px;
  border-radius: var(--lia-bs-input-border-radius);

  &.lia-control-is-focused {
    border-color: hsl(
      var(--lia-bs-primary-h),
      var(--lia-bs-primary-s),
      calc(var(--lia-bs-primary-l) + 25%)
    );
    box-shadow: 0 0 0 3px
      hsla(var(--lia-bs-primary-h), var(--lia-bs-primary-s), var(--lia-bs-primary-l), 0.25);
  }
}

.lia-menu.lia-menu {
  position: static;
  width: auto;
  border: 0;
  box-shadow: none;
  border-radius: 0;
  margin: 0;
  text-transform: uppercase;

  .lia-menu-list {
    margin: 0;
    padding: 5px 0 0;
    border-bottom: 1px solid var(--lia-bs-gray-300);

    &.lia-menu-list-has-create-option {
      padding: 0;
      margin-top: 5px;
      border: none;
    }
    &:empty {
      display: none;
    }
  }
}

.lia-option.lia-option {
  padding: 5px 15px;
  max-width: 300px;
  overflow-wrap: break-word;

  &.lia-option-is-create {
    font-size: var(--lia-bs-font-size-sm);
    color: var(--lia-bs-body-color);
  }
  &.lia-option-is-focused {
    background-color: var(--lia-bs-gray-200);
  }
  &.lia-option-is-create + .lia-option {
    margin-top: 5px;
    border-top: 1px solid var(--lia-bs-gray-300);
  }
  &:hover,
  &:focus {
    background-color: var(--lia-bs-gray-200);
  }
}

.lia-tag-create {
  display: flex;
  align-items: center;
  max-width: 300px;
  word-break: break-all;
  text-transform: none;

  .lia-tag-create-icon {
    align-self: flex-start;
    width: 100%;
    margin-right: 3px;
    margin-top: 5px;
  }
}

.lia-tag-select {
  display: flex;
  flex-direction: column-reverse;
}
